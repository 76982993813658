<template>
  <slide-over-right
    :id="sorId"
    editType="marketplace"
    v-on:showModal="showModal"
  >
    <loading :active.sync="isLoading"></loading>

    <div class="flex flex-wrap px-6 pt-12 w-full">
      <div class="items-center  panel-title header-title">
        {{ titleText }}
      </div>
    </div>

    <div class="px-6">
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(confirmSubmit)"
          enctype="multipart/form-data"
        >
          <UploadAvatar
            v-model="form.brand_image"
            :current="form.brand_image"
            :class="`mb-3`"
          />

          <div class="flex items-center pt-8">
            <div class="pr-2 w-1/2">
              <AppInput
                v-model="form.brand_name"
                type="text"
                :name="$t('components.marketPlaceManagement.orders.addEdit.title.brandName')"
                :label="$t('components.marketPlaceManagement.orders.addEdit.title.brandName')"
                rules="required"
                placeholder="e.g. DUK"
                :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.brandName')"
              />
            </div>
            <div class="pl-2 w-1/2">
              <AppInput
                v-model="form.fleet"
                type="richselect"
                  :name="$t('components.marketPlaceManagement.orders.addEdit.title.assignedFleet')"
                :label="$t('components.marketPlaceManagement.orders.addEdit.title.assignedFleet')"
                rules="required"
                :placeholder="$t('components.marketPlaceManagement.orders.addEdit.placeHolder.assignedFleet')"
                value-attribute="id"
                text-attribute="name"
                 :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.assignedFleet')"
                :options="fleets"
                :hide-search-box="true"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <AppInput
                v-model="form.lock_manufacturer"
                type="richselect"
                 :name="$t('components.marketPlaceManagement.orders.addEdit.title.assignedManufacture')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.assignedManufacture')"
                rules="required"
                :placeholder="$t('components.marketPlaceManagement.orders.addEdit.placeHolder.assignedManufacture')"
                value-attribute="id"
                text-attribute="name"
              :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.assignedManufacture')"
                :options="lockManufacturers"
                :hide-search-box="true"
              />
            </div>
            <div class="pl-2 w-1/2">
              <AppInput
                v-model="form.unit_vehicle_price"
                type="number"
                step="0.01"
                :name="getPerVehiclePriceTitle"
                :label="getPerVehiclePriceTitle"
                rules="required"
                placeholder="e.g. 100"
                 :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.vehiclePrice')"
              />
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <AppInput
                v-model="form.number_of_vehicles_to_deploy"
                type="number"
                :name="$t('components.marketPlaceManagement.orders.addEdit.title.deploy')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.deploy')"
                rules="required"
                placeholder="e.g. 100"
                 :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.deploy')"
              />
            </div>
            <div class="pl-2 w-1/2">
              <AppInput
                v-model="form.company_employee_size"
                type="richselect"
                :name="$t('components.marketPlaceManagement.orders.addEdit.title.employeeSize')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.employeeSize')"
                rules="required"
                :placeholder="$t('components.marketPlaceManagement.orders.addEdit.placeHolder.employeeSize')"
                value-attribute="value"
                text-attribute="text"
                :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.employeeSize')"
                :options="employeeSizes"
                :hide-search-box="true"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <AppInput
                v-model="form.business_model"
                type="richselect"
                 :name="$t('components.marketPlaceManagement.orders.addEdit.title.businessModel')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.businessModel')"
                rules="required"
               :placeholder="$t('components.marketPlaceManagement.orders.addEdit.placeHolder.businessModel')"
                value-attribute="value"
                text-attribute="text"
               :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.businessModel')"
                :options="businessModels"
                :hide-search-box="true"
              />
            </div>
            <div class="pl-2 w-1/2">
              <AppInput
                v-model="form.target_group"
                type="richselect"
                 :name="$t('components.marketPlaceManagement.orders.addEdit.title.targetGroup')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.targetGroup')"
                rules="required"
                :placeholder="$t('components.marketPlaceManagement.orders.addEdit.placeHolder.targetGroup')"
                value-attribute="value"
                text-attribute="text"
              :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.targetGroup')"
                :options="targetGroups"
                :hide-search-box="true"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <AppInput
                v-model="form.year_founded"
                type="date"
                 :name="$t('components.marketPlaceManagement.orders.addEdit.title.foundedDate')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.foundedDate')"
                rules="required"
                 :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.foundedDate')"
              />
            </div>
            <div class="pl-2 w-1/2">
              <AppInput
                v-model="form.operation_start_date"
                type="date"
                :name="$t('components.marketPlaceManagement.orders.addEdit.title.operationStartDate')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.operationStartDate')"
                rules="required"
               :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.operationStartDate')"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <AppInput
                v-model="form.order_start_date"
                type="date"
                :name="$t('components.marketPlaceManagement.orders.addEdit.title.orderStartDate')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.orderStartDate')"
                rules="required"
                :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.orderStartDate')"
              />
            </div>
            <div class="pl-2 w-1/2">
              <AppInput
                v-model="form.order_end_date"
                type="date"
                :name="$t('components.marketPlaceManagement.orders.addEdit.title.orderEndDate')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.orderEndDate')"
                rules="required"
                 :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.orderEndDate')"
              />
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <AppInput
                v-model="form.rent_share_1st_year_by_percentage"
                type="number"
                step="0.01"
                :name="$t('components.marketPlaceManagement.orders.addEdit.title.rentShare1styear')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.rentShare1styear')"
                rules="required"
                  :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.rentShare1styear')"
              />
            </div>
            <div class="pl-2 w-1/2">
              <AppInput
                v-model="form.rent_share_2nd_year_by_percentage"
                type="number"
                step="0.01"
               :name="$t('components.marketPlaceManagement.orders.addEdit.title.rentShare2ndyear')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.rentShare2ndyear')"
                rules="required"
                :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.rentShare2ndyear')"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <AppInput
                v-model="form.rent_share_3rd_year_by_percentage"
                type="number"
                step="0.01"
                 :name="$t('components.marketPlaceManagement.orders.addEdit.title.rentShare3rdyear')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.rentShare3rdyear')"
                rules="required"
                :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.rentShare3rdyear')"
              />
            </div>
            <div class="pl-2 w-1/2">
              <AppInput
                v-model="form.rent_share_4th_year_by_percentage"
                type="number"
                step="0.01"
                :name="$t('components.marketPlaceManagement.orders.addEdit.title.rentShare4thyear')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.rentShare4thyear')"
                rules="required"
                :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.rentShare4thyear')"
              />
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="w-full">
              <AppInput
                v-model="form.business_id"
                type="text"
                 :name="$t('components.marketPlaceManagement.orders.addEdit.title.businessId')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.businessId')"
                placeholder="e.g. B23456789"
                :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.businessId')"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <AppInput
                v-model="form.fleet_address"
                type="text"
                :name="$t('components.marketPlaceManagement.orders.addEdit.title.address')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.address')"
                placeholder="e.g. 123 Street View,London"
                  :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.address')"
              />
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="w-full">
              <AppInput
                v-model="form.website_url"
                type="text"
                :name="$t('components.marketPlaceManagement.orders.addEdit.title.websiteURL')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.websiteURL')"
                placeholder="https://www.example.com/id"
                    :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.websiteURL')"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <AppInput
                v-model="form.linkedin_url"
                type="text"
                 :name="$t('components.marketPlaceManagement.orders.addEdit.title.linkedInURL')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.linkedInURL')"
                placeholder="https://www.linkedin.com/id"
               :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.linkedInURL')"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <AppInput
                v-model="form.facebook_url"
                type="text"
                :name="$t('components.marketPlaceManagement.orders.addEdit.title.facebookURL')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.facebookURL')"
                placeholder="https://www.facebook.com/id"
                :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.facebookURL')"
              />
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="w-full">
              <AppInput
                v-model="form.instagram_url"
                type="text"
              :name="$t('components.marketPlaceManagement.orders.addEdit.title.instagramURL')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.instagramURL')"
                placeholder="https://www.instagram.com/id"
                    :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.instagramURL')"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <AppInput
                v-model="form.instagram_url"
                type="text"
                 :name="$t('components.marketPlaceManagement.orders.addEdit.title.youtubeURL')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.youtubeURL')"
                placeholder="https://www.youtube.com/id"
                 :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.youtubeURL')"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <AppInput
                v-model="form.android_play_store_url"
                type="text"
                :name="$t('components.marketPlaceManagement.orders.addEdit.title.playstoreURL')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.playstoreURL')"
                placeholder="https://www.playstore.com/id"
               :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.playstoreURL')"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <AppInput
                v-model="form.ios_app_store_url"
                type="text"
                 :name="$t('components.marketPlaceManagement.orders.addEdit.title.appstoreURL')"
              :label="$t('components.marketPlaceManagement.orders.addEdit.title.appstoreURL')"
                placeholder="https://www.apple.com/id"
                :infoDescription="$t('components.marketPlaceManagement.orders.addEdit.infoDescription.appstoreURL')"
              />
            </div>
          </div>

          <button type="submit" ref="submitButton" class="hidden">{{
                $t('components.stepNavigation.save')
              }}</button>
        </form>
      </ValidationObserver>
      <div class="flex justify-end items-center pr-2 mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">{{
            $t('components.stepNavigation.cancel')
          }}</anchor-button>
        </slide-over-right-trigger>
        <t-button type="submit" @click="submit()" class="ml-3">{{
                $t('components.stepNavigation.save')
              }}</t-button>
      </div>
    </div>
  </slide-over-right>
</template>
<script>
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
import { EventBus } from '@/utils/EventBus'
import xMan from '@/utils/xMan'
import SlideOverRight from '@/components/modals/SlideOverRight'
import AnchorButton from '@/components/form/AnchorButton'
import { UploadAvatar } from '@/components/form'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
export default {
  name: 'AddEditVehicleManufacture',
  components: {
    SlideOverRight,
    AnchorButton,
    SlideOverRightTrigger,
    UploadAvatar,
  },
  data() {
    return {
      sorId: MarketplaceConfig.events.sorId,
      isLoading: false,
      perVehiclePriceCurrency: '',
      form: {
        brand_image: null,
        brand_name: null,
        fleet: null,
        lock_manufacturer: null,
        unit_vehicle_price: null,

        number_of_vehicles_to_deploy: null,
        business_model: null,
        target_group: null,
        company_employee_size: null,

        year_founded: null,
        order_start_date: null,
        order_end_date: null,
        operation_start_date: null,

        rent_share_1st_year_by_percentage: null,
        rent_share_2nd_year_by_percentage: null,
        rent_share_3rd_year_by_percentage: null,
        rent_share_4th_year_by_percentage: null,

        business_id: null,
        fleet_address: null,

        website_url: null,
        facebook_url: null,
        linkedin_url: null,
        instagram_url: null,
        youtube_url: null,
        android_play_store_url: null,
        ios_app_store_url: null,
      },
      itemId: '',
      fleets: [],
      lockManufacturers: [],
      businessModels: [
        { text: 'Sharing', value: 'SH' },
        { text: 'Store Rental', value: 'SR' },
      ],
      targetGroups: [
        { text: 'B2B', value: 'B2B' },
        { text: 'B2C', value: 'B2C' },
      ],
      employeeSizes: [
        {
          text: '1-10',
          value: '1-10',
        },
        {
          text: '11-50',
          value: '11-50',
        },
        {
          text: '51-100',
          value: '51-100',
        },
        {
          text: '101-250',
          value: '101-250',
        },
        {
          text: '251-500',
          value: '251-500',
        },
        {
          text: '501-1000',
          value: '501-1000',
        },
        {
          text: '1001-2500',
          value: '1001-2500',
        },
        {
          text: '2501-5000',
          value: '2501-5000',
        },
        {
          text: '5001-10000',
          value: '5001-10000',
        },
        {
          text: '10000+',
          value: '10000+',
        },
      ],
    }
  },
  computed: {
    isEditing: function() {
      // enabled status-switch by default
      return this.itemId.length > 1
    },
    titleText() {
      return this.isEditing ? this.$t(
            'components.marketPlaceManagement.orders.addEdit.headline.edit'
          )
        : this.$t('components.marketPlaceManagement.orders.addEdit.headline.add')
    },
    getPerVehiclePriceTitle() {
      if (this.perVehiclePriceCurrency) {
       return `${this.$t('components.marketPlaceManagement.orders.addEdit.title.perVehiclePrice')} (${this.perVehiclePriceCurrency})`;
      } else {
        return this.$t(
            'components.marketPlaceManagement.orders.addEdit.title.perVehiclePrice'
          )
      }
    },
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler(updatedData) {
        if (updatedData.fleet && this.fleets) {
          const selectedFleet = this.fleets.find(
            (item) => item.id === updatedData.fleet
          )
          this.perVehiclePriceCurrency = selectedFleet.country.currency_symbol
        }
      },
    },
  },
  mounted() {
    EventBus.$on(MarketplaceConfig.events.editingData, (data) => {
      this.perVehiclePriceCurrency = ''
      this.init()
      this.itemId = data.itemId
      this.lockManufacturers = data.lockManufacturers
      this.fleets = data.fleets
      if (data.itemId) {
        this.isLoading = true
        this.$http
          .get(MarketplaceConfig.api.details(data.itemId))
          .then((res) => {
            this.isLoading = false
            const resData = res.data.data
            this.form.brand_image = resData.brand_image
            this.form.brand_name = resData.brand_name
            this.form.fleet = resData.fleet.id
            this.form.lock_manufacturer = resData.lock_manufacturer.id
            this.form.unit_vehicle_price = resData.unit_vehicle_price
            this.form.number_of_vehicles_to_deploy =
              resData.total_vehicle_number
            this.form.business_model = resData.business_model
            this.form.target_group = resData.target_group
            this.form.company_employee_size = resData.company_employee_size
            this.form.year_founded = this.dateSplitter(resData.year_founded)

            this.form.order_start_date = this.dateSplitter(
              resData.order_start_date
            )
            this.form.order_end_date = this.dateSplitter(resData.order_end_date)
            this.form.operation_start_date = this.dateSplitter(
              resData.operation_start_date
            )
            this.form.rent_share_1st_year_by_percentage =
              resData.rent_share_1st_year_by_percentage
            this.form.rent_share_2nd_year_by_percentage =
              resData.rent_share_2nd_year_by_percentage
            this.form.rent_share_3rd_year_by_percentage =
              resData.rent_share_3rd_year_by_percentage
            this.form.rent_share_4th_year_by_percentage =
              resData.rent_share_4th_year_by_percentage

            this.form.business_id = resData.business_id

            this.form.fleet_address = resData.fleet_address

            this.form.website_url = resData.website_url
            this.form.facebook_url = resData.facebook_url
            this.form.linkedin_url = resData.linkedin_url
            this.form.instagram_url = resData.instagram_url
            this.form.youtube_url = resData.youtube_url
            this.form.android_play_store_url = resData.android_play_store_url
            this.form.ios_app_store_url = resData.ios_app_store_url
          })
          .catch((e) => console.log(e))
        //edit
      }
    })
  },
  methods: {
    dateSplitter(dString) {
      return dString.split('T')[0]
    },
    showModal() {
      dispatchEvent(new Event(MarketplaceConfig.events.sorToggle))
    },
    init() {
      this.form.brand_image = null
      this.form.brand_name = null
      this.form.fleet = null
      this.form.lock_manufacturer = null
      this.form.unit_vehicle_price = null
      this.form.number_of_vehicles_to_deploy = null
      this.form.business_model = null
      this.form.target_group = null
      this.form.company_employee_size = null
      this.form.year_founded = null
      this.form.order_start_date = null
      this.form.order_end_date = null
      this.form.operation_start_date = null
      this.form.rent_share_1st_year_by_percentage = null
      this.form.rent_share_2nd_year_by_percentage = null
      this.form.rent_share_3rd_year_by_percentage = null
      this.form.rent_share_4th_year_by_percentage = null
      this.form.business_id = null
      this.form.fleet_address = null
      this.form.website_url = null
      this.form.facebook_url = null
      this.form.linkedin_url = null
      this.form.instagram_url = null
      this.form.youtube_url = null
      this.form.android_play_store_url = null
      this.form.ios_app_store_url = null
    },
    submit() {
      this.$refs.submitButton.click()
    },
    async confirmSubmit() {
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? MarketplaceConfig.api.update(this.itemId)
        : MarketplaceConfig.api.create()

      const formProxy = {
        ...this.form,
      }

      const propertyNames = Object.keys(formProxy)
      propertyNames.map((name) => {
        if (formProxy[name] === null) {
          delete formProxy[name]
        }
      })
      if (typeof this.form.brand_image === 'string') {
        delete formProxy.brand_image
      }

      formProxy.year_founded = formProxy.year_founded + 'T00:00:00'
      formProxy.order_start_date = formProxy.order_start_date + 'T00:00:00'
      formProxy.order_end_date = formProxy.order_end_date + 'T00:00:00'
      formProxy.operation_start_date =
        formProxy.operation_start_date + 'T00:00:00'
      let data = new xMan(formProxy).toFormData()
      let message = this.isEditing
        ? 'Marketplace updated successfully'
        : 'Marketplace added successfully'

      this.isLoading = true

      try {
        let response = await this.$http({
          method,
          url,
          data,
        })

        console.log('response = ', url, '  ', method, '  ')
        console.log('data = ', response)

        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(MarketplaceConfig.events.sorToggle))
        dispatchEvent(new Event(MarketplaceConfig.events.refresh))
        this.$emit('add-edit')
        // Reset the form
        this.init()
        this.$notify({
          group: 'bottomLeft',
          type: 'success',
          title: 'Success',
          text: message,
        })
        // Resolved
        return response.status
      } catch (error) {
        const e = error.response
        console.log('err', e)

        this.$notify(
          {
            group: 'bottomLeft',
            type: 'error',
            title: `Error Occured [Code: ${e.status}]`,
            text: e.data ?? 'The action could not be executed',
          },
          7000
        )

        this.isLoading = false
      }
    },
  },
}
</script>
<style lang="scss">
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
</style>
