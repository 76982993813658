<template>
  <BaseLayout>
    <Fleet :pageTitle="$t('components.fleetManagement.headline')" />
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import Fleet from '@/views/fleet/index.vue'

export default {
  name: 'FleetIndex',
  components: {
    BaseLayout,
    Fleet,
  },
}
</script>
